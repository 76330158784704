import resume from "../assets/pdf/RaniRajLaxmiSoni-Resume-Animation.pdf";
// import { pro_pic } from "./myProfile.jpg";

export const headerData = {
  name: "Rani Raj Laxmi Soni",
  title: "3D Artist",
  desciption:
    "A passionate artist with creative and imaginative skills, who is looking out to explore every aspect of 3D Modeling and Animation. So...let's connect! and start Creating!",
  resumePdf: resume,
};
