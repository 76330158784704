import React, {createContext, useEffect, useState} from "react";

import { themeData } from "../data/themeData";

export const ThemeContext = createContext({});


function ThemeContextProvider(props) {
  // eslint-disable-next-line
  // const [theme, setTheme] = useState(themeData.theme)

  const [drawerOpen, setDrawerOpen] = useState(false);

  const setHandleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [theme, setTheme] = useState(themeData.defaultTheme);

  useEffect(() => {

    const localTheme = JSON.parse(localStorage.getItem("themeData"));

    if (!localTheme) {
      localStorage.setItem('themeData', JSON.stringify(themeData.defaultTheme));
      setTheme(themeData.defaultTheme);
    }

    if (localTheme) {
      if (localTheme === themeData.defaultTheme) {
        localStorage.setItem('themeData', JSON.stringify(themeData.defaultTheme));
        setTheme(themeData.defaultTheme);
      }

      if (localTheme === themeData.secondaryTheme) {
        localStorage.setItem('themeData', JSON.stringify(themeData.secondaryTheme));
        setTheme(themeData.secondaryTheme);
      }
    }
    console.log("localTheme")
    console.log(localTheme)

  }, [])


  //   const value = { theme, drawerOpen, setHandleDrawer };
  const value = { theme, drawerOpen, setHandleDrawer, setTheme };
  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
