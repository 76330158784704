export const socialsData = {
  // github: "https://github.com/rajlaxmisoni",
  linkedIn: "https://www.linkedin.com/in/rajlaxmisoni",
  instagram: "https://www.instagram.com/_r_dimensions/",
  // codepen: "https://codepen.io/",
  twitter: "https://twitter.com/ranirajsoni",
  // reddit: "https://www.reddit.com/user/",
  // blogger: "https://www.blogger.com/",
  // medium: "https://medium.com/",
  // stackOverflow: "https://stackoverflow.com/users/",
  // gitlab: "https://gitlab.com/",
  // youtube: "https://youtube.com/",
  resume:
    "https://drive.google.com/file/d/10Y3-OC3dmG-4KrO9nHDuRnindCQhWhrC/view?usp=share_link",
};
