import nhm_hall_closeup from "../assets/portfolio/changelab/nhm_hall/nhm_hall_closeup.png";
import triceratops_image from "../assets/portfolio/changelab/triceratops_changelab/ZBrush_Render_Triceratops_Side.jpeg";
import trex_image from "../assets/portfolio/changelab/trex_changelab/ZBrush_Render_Trex.jpg";
import typewriter_image from "../assets/portfolio/vintage_typewriter/vintage-typewriter-front.jpg";
import train_tunnel_image from "../assets/portfolio/train_tunnel/train-tunnel.jpeg";
import gas_station_image from "../assets/portfolio/gas_station/gas-station-front.jpeg";
import leg_fish_image from "../assets/portfolio/leg_fish/leg-fish.jpeg";
import amurich_image from "../assets/portfolio/amurich/amurich_front.jpeg";
import cari_lincoln_image from "../assets/portfolio/abraham_lincoln/lincoln-caricature.jpeg";
import imp_warrior_image from "../assets/portfolio/imperium_warrior/imperium_warrior_front.png";
export const projectsData = [

  {
    id: 1,
    projectName: "Leg Fish - 3D Model",
    projectDesc:
      "I sculpted this 3D Model of a 'Leg Fish' as a part of a 3D Animated Short (in Production) directed by Joel Benjamin, based on a concept art by Kimberly Underwood.",
    tags: ["ZBrush"],
    code: "https://www.artstation.com/artwork/14wWOo",
    demo: "https://www.artstation.com/artwork/14wWOo",
    image: leg_fish_image,
  },
  {
    id: 2,
    projectName: "Amurich - 3D Model",
    projectDesc:
      "This is a fictional environment based character is inspired by the animals living in the Kalahari Desert, based in southern part of African continent. A biped character having fairly shorter and deformed front limbs, whereas it uses them to grab and eat food.",
    tags: ["ZBrush", "Adobe Photoshop"],
    code: "https://www.artstation.com/artwork/Ye3Vgb",
    demo: "https://www.artstation.com/artwork/Ye3Vgb",
    image: amurich_image,
  },
  {
    id: 3,
    projectName: "Caricature - Abraham Lincoln - 3D Sculpture",
    projectDesc:
      "It is a 3D Sculpt of Abraham Lincoln. I started with the basic head sculpt of him and later modified and enhanced the features of sculpt as caricature and then polypainted it in ZBrush",
    tags: ["ZBrush"],
    code: "https://www.artstation.com/artwork/rAQyD2",
    demo: "https://www.artstation.com/artwork/rAQyD2",
    image: cari_lincoln_image,
  },
  {
    id: 4,
    projectName: "Gas Station - 3D Model",
    projectDesc:
        "The Gas Station Diorama I finished as a part of a coursework project. I modeled it in Maya, textured it in Substance Painter and rendered it in Arnold.",
    tags: ["Maya", "Substance 3D Painter", "Substance 3D Sampler"],
    code: "https://www.artstation.com/artwork/wJ0KqO",
    demo: "https://www.artstation.com/artwork/wJ0KqO",
    image: gas_station_image,
  },
  {
    id: 5,
    projectName: "Train Tunnel - Lighting, Texturing",
    projectDesc:
        "Worked on the Train Tunnel as a part of a coursework project. UV’ed and textured the Scene in Maya. Lighting and final renders are achieved in Maya Arnold.\nTechnologies: Maya Arnold and Substance 3D Sampler\n",
    tags: ["Maya", "Substance 3D Sampler", "Arnold"],
    code: "https://www.artstation.com/artwork/JeW9WD",
    demo: "https://www.artstation.com/artwork/JeW9WD",
    image: train_tunnel_image,
  },
  {
    id: 6,
    projectName: "Vintage TypeWriter - 3D Model",
    projectDesc:
        "Inspired by Halda Green portable typewriters. Modelled in Maya, UV’d in Maya, textured in Substance Painter and Maya. The final low poly renders are achieved in Maya Arnold.",
    tags: ["Maya", "Substance 3D Painter"],
    code: "https://www.artstation.com/artwork/g8aKXx",
    demo: "https://www.artstation.com/artwork/g8aKXx",
    image: typewriter_image,
  },
  {
    id: 7,
    projectName: "T-Rex Skeleton - 3D Model",
    projectDesc:
        "This is a work I did for one of my clients for an undisclosed project. Its Modelled and rendered in ZBrush",
    tags: ["ZBrush"],
    code: "https://drive.google.com/drive/folders/1rE2yERCN8EqoUax7QrNbs8-SiSVHBJwU?usp=share_link",
    demo: "https://drive.google.com/drive/folders/1rE2yERCN8EqoUax7QrNbs8-SiSVHBJwU?usp=share_link",
    image: trex_image,
  },
  {
    id: 8,
    projectName: "Triceratops Skeleton - 3D Model",
    projectDesc:
        "This is a work I did for one of my clients for an undisclosed project. Its Modelled and rendered in ZBrush",
    tags: ["ZBrush"],
    code: "https://drive.google.com/drive/folders/1m0JyR1ivNNdM_3DjZSzk6q34KSloNhb2?usp=share_link",
    demo: "https://drive.google.com/drive/folders/1m0JyR1ivNNdM_3DjZSzk6q34KSloNhb2?usp=share_link",
    image: triceratops_image,
  },
  {
    id: 9,
    projectName: "Natural History Museum - Hall",
    projectDesc:
        "This is an un-release work for one of my clients for an undisclosed project. Its Modelled" +
        " and" +
        " rendered in Maya Arnold and Textured in Substance painter",
    tags: ["Maya", "Substance Painter"],
    code: "https://www.artstation.com/artwork/398Kvv",
    demo: "https://www.artstation.com/artwork/398Kvv",
    image: nhm_hall_closeup,
  },
  {
    id: 10,
    projectName: "Imperium Warrior",
    projectDesc:
        "Immerse yourself in the captivating world of the Imperium Warrior!\n" +
        "This character art is inspired by the fantastic concept Art by Mikel Rekondo." +
        "This was modelled in ZBRush and rendered in Maya Arnold and Textured in Substance painter." +
        "The Real time rendering was done in Unreal Engine",
    tags: ["Maya", "Substance Painter", "Unreal Engine"],
    code: "https://www.artstation.com/artwork/49RJD8",
    demo: "https://www.artstation.com/artwork/49RJD8",
    image: imp_warrior_image,
  }
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
