/* eslint-disable */
import {
  // greenThemeLight,
  // greenThemeDark,
  // bwThemeLight,
  // bwThemeDark,
  // blueThemeLight,
  // blueThemeDark,
  // redThemeLight,
  // redThemeDark,
  // orangeThemeLight,
  // orangeThemeDark,
  // purpleThemeLight,
  // purpleThemeDark,
  // pinkThemeLight,
  // pinkThemeDark,
  // yellowThemeLight,
  // yellowThemeDark,
  lightPinkThemeLight, lightPinkThemeDark,
} from "../theme/theme";

export const themeData = {
  // theme: bwThemeDark,
  defaultTheme: lightPinkThemeLight,
  secondaryTheme: lightPinkThemeDark,
};

// Choose theme from above
